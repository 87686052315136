import { useEffect, useState } from 'react'

import { VehicleCard } from '@/components/styleguide/Card/Vehicle/VehicleCard'
import { Carousel } from '@/components/styleguide/Carousel'
import { constants } from '@/lib/constants'
import { storageAvailable } from '@/lib/utilities/system'
import { SearchResult } from '@/types/search'

export type RecentlyViewedProps = {
  isVans: boolean
}

export const RecentlyViewed = ({ isVans }: RecentlyViewedProps) => {
  const [recentlyViewed, setRecentlyViewed] = useState<SearchResult[]>([])

  useEffect(() => {
    if (storageAvailable('localStorage')) {
      const storedData = localStorage.getItem(
        isVans
          ? constants.localStorageKey.recentlyViewedVans
          : constants.localStorageKey.recentlyViewedCars,
      )
      setRecentlyViewed(storedData ? JSON.parse(storedData).reverse() : [])
    }
  }, [isVans])

  if (recentlyViewed.length === 0) {
    return null
  }

  return (
    <Carousel
      header={<h2>Recently viewed</h2>}
      scrollValue={408}
      showMoreButton={false}
      sectionName="recently-viewed">
      {recentlyViewed.slice(0, 12).map(deal => (
        <VehicleCard
          deal={deal}
          key={deal.vehiclePrice.id}
          type="carousel"
        />
      ))}
    </Carousel>
  )
}
