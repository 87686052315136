import styles from './hero-background.module.scss'
import { TrustpilotStarWidget } from '@/components/styleguide/TrustpilotStarWidget'

export const HeroBackgroundInContainer = ({ children, isVans }) => {
  return (
    <>
      <div
        className={`${styles.background} ${styles['variant-2']} ${isVans ? styles['is-vans'] : styles['is-cars']}`}>
        <svg
          className={styles.confetti}
          width="702"
          height="418"
          viewBox="0 0 702 418"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M537.5 19.6554C568.438 -8.4696 592.613 13.9703 597.312 23.9679C616.026 59.4208 587.75 91.0928 569.75 82.5171C549.409 70.7737 576.875 12.6538 620.188 22.2163C668.018 35.6138 654.652 113.177 628.062 103.216C596.209 91.2832 663.93 12.8568 695.562 59.4054"
            stroke="#FFCC00"
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M106.577 269.212C85.7763 263.907 74.4715 262.373 57.4589 259.39C67.5693 278.695 72.7935 289.5 80.8665 308.675C62.3696 307.174 50.4262 306.003 33.9068 299.59C39.1416 317.849 45.8847 328.781 56.5117 347.982C38.7862 345.486 26.9064 344.044 9.89817 343.705C19.8089 368.307 25.0328 382.201 29.4141 408.501"
            stroke="#FFCC00"
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.0006 92.1806C47.4025 77.3851 55.3079 70.974 77.2623 64.1516"
            stroke="#FF2D55"
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M577.783 243.385C589.178 256.019 598.281 260.574 621.19 262.5"
            stroke="#FF2D55"
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {children}
      </div>
      <div className={styles.widget}>
        <TrustpilotStarWidget theme="light" />
      </div>
    </>
  )
}
