import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useDispatch } from 'react-redux'

import styles from './search.module.scss'
import { ButtonHierarchy } from '@/components/styleguide/Button'
import { InternalLink } from '@/components/styleguide/Link'
import { TrustpilotStarWidget } from '@/components/styleguide/TrustpilotStarWidget'
import { constants } from '@/lib/constants'
import { useExperiment } from '@/lib/hooks/useExperiment'
import { addToDataLayer } from '@/lib/utilities/vehicle'
import { resetSearchState } from '@/store/reducers/search'
import { Size, Theme } from '@/types/system'

const MediaQuery = dynamic(() => import('react-responsive'), {
  ssr: false,
})

const Animation = dynamic(() => import('../Animation'), { ssr: false })

const savedSearchReferences = [
  { ref: '4d56b4a8c34e632f514f4fa957ad65d8', label: 'Under £250/pm' },
  { ref: 'b960d43d5d3c027d4926af1a646c536f', label: 'Family cars' },
  { ref: 'ddacda9c0bcc3b550c12c696e7049b18', label: 'Electric cars' },
]

export const Search = ({
  isVans,
  children,
  showBottomDash = true,
  popularSearchesVisibility = 'always',
}) => {
  const dispatch = useDispatch()
  const [experimentGroup] = useExperiment()
  const [variantName, setVariantName] = useState('control')

  useEffect(() => {
    if (experimentGroup.startsWith('homepage-hero-containerise-variant')) {
      setVariantName(experimentGroup.split('homepage-hero-containerise-')[1])
    }
  }, [experimentGroup])

  useEffect(() => {
    dispatch(resetSearchState())
  }, [dispatch])

  const onLinkClick = item => {
    addToDataLayer({
      event: 'homepage-search-chip',
      label: item.label,
      experimentGroup,
    })
  }

  return (
    <section className={`${styles.container} ${styles[variantName]}`}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h1>Your {isVans ? 'van' : 'car'} leasing compadre</h1>
          <h2>Compare millions of deals from over 80 providers.</h2>
        </div>
        <div>
          {children}
          {!isVans && popularSearchesVisibility !== 'never' && (
            <MediaQuery
              minWidth={variantName === 'control' ? 0 : 768}
              maxWidth={variantName === 'control' ? 767 : undefined}>
              {matches =>
                matches && (
                  <div className={styles.pills}>
                    <p className={styles.pills__title}>
                      Popular
                      <MediaQuery minWidth={992}>
                        {matches => (matches ? ' searches' : '')}
                      </MediaQuery>
                      :
                    </p>
                    <ul className={styles.pills__container}>
                      {savedSearchReferences.map((item, idx) => (
                        <li key={idx}>
                          <InternalLink
                            hierarchy={ButtonHierarchy.Borderless}
                            theme={Theme.dark}
                            size={Size.Medium}
                            hasUnderline
                            onClick={() => onLinkClick(item)}
                            href={`/car-leasing/search/${item.ref}`}>
                            {item.label}
                          </InternalLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              }
            </MediaQuery>
          )}
        </div>
      </div>
      <div className={styles['rear-mirror']}>
        <Image
          src={`${constants.imageHost.assets}/homepage/rear-mirror.svg`}
          height="148"
          width="349"
          alt="rear view mirror"
          priority
        />
      </div>

      <Fade
        triggerOnce
        className={styles.animation}>
        <Animation variant={variantName !== 'control' && variantName} />
      </Fade>
      <div className={styles.extras}>
        <div className={styles.widget}>
          <TrustpilotStarWidget theme="dark" />
        </div>
      </div>
      {showBottomDash && (
        <div className={styles.bottom}>
          <Image
            src={`${constants.imageHost.assets}/homepage/bottom-dash.svg`}
            alt="Bottom of a dashboard"
            layout="fill"
            priority
          />
        </div>
      )}
    </section>
  )
}
